import React from "react"
import styled from "styled-components"

import { Layout } from "../components/layout"

import PageHeading from "../components/PageHeading/PageHeading"
import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import { Container } from "@material-ui/core"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Get in Touch ",
    url: "/get-in-touch",
  },
]

const ContactUs: React.FC = ({ data }) => {
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)

  return (
    <Layout title={"Graduate Fashion Week - Get in Touch"}>
      <PageHeading breadcrumbData={breadcrumbData} pageTitle="Get in Touch" />
      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
      <PortfoliosContainer></PortfoliosContainer>
    </Layout>
  )
}

export default ContactUs

export const pageQuery = graphql`
  query contactUs {
    wpPage(title: { eq: "Get in Touch" }) {
      id
      lazy_data
      title
    }
  }
`

const PortfoliosContainer = styled.div`
  width: 100vw;
  max-width: 1200px;
  justify-content: center;
  z-index: 10;
  margin-bottom: 80px;
`
